<template>
  <div class="intro-content">
    <div
      v-for="(item, index) in lotIntroNav" :key="index">
        <div class="intro-content-title">{{item.title}}</div>
        <div class="intro-content-container">
          <div
            class="content-item pointer"
            v-for="(itemChild, indexChild) in item.children" :key="indexChild">
              <div
                @click="handleOpenDetail(itemChild, indexChild, index)">
                {{itemChild.subTitle}}
              </div>
          </div>
        </div>
        <div v-if="index !== lotIntroNav.length -1" class="content-line"></div>
    </div>
  </div>
</template>

<script>
import { lotIntroNav } from '@/constants/lotIntroNav'

export default {
  data () {
    return {
      lotIntroNav: lotIntroNav
    }
  },
  methods: {
    handleOpenDetail (item, indexChild, index) {
      window.scrollTo({top: 0, behavior: 'smooth'});
      this.$emit('openDetail', item, indexChild, 'LotDetail', index)
    }
  }
}
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
.intro-content {
  padding: 0 0 0 70px;
  .intro-content-title {
    text-align: left;
    padding: 0 0 20px 0;
    font-weight: 500;
    font-size: 21px;
    color: #005FDE;
  }
  .intro-content-container {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    .content-item {
      font-size: 14px;
      line-height: 20px;
      color: #333333;
      padding: 0 0 20px 0;
      width: 25%;
      & > div {
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &::before {
          content: '•';
          display: inline-block;
          font-size: 20px;
          font-weight: 500;
          line-height: 22px;
          color: #BDBDBD;
          vertical-align: middle;
        }
      }
      &:hover {
        color: #005FDE;
        font-weight: 500;
        & > div {
          &::before {
            color: #56CCF2;
          }
        }
      }
    }
  }
}
.content-line {
  margin: 5px 0 25px 0;
  width: 100%;
  border: 1px solid #eee;
}
@media (max-width: 575.98px) {
  .intro-content {
    padding: 5px 15px;
    .intro-content-title {
      padding: 20px 0;
      font-weight: 500;
      font-size: 16px;
    }
    .intro-content-container {
      .content-item {
        font-size: 12px;
        line-height: 17px;
        padding: 0 0 25px 0;
        width: 33.3%;
      }
    }
  }
  .content-line {
    margin: 5px 0 0;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .intro-content {
    padding: 5px 15px 5px 25px;
    .intro-content-title {
      padding: 20px 0;
      font-weight: 500;
      font-size: 16px;
    }
    .intro-content-container {
      .content-item {
        font-size: 12px;
        line-height: 17px;
        padding: 0 0 25px 0;
        width: 25%;
      }
    }
  }
  .content-line {
    margin: 5px 0 0;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .intro-content {
    padding: 0 0 0 50px;
    .intro-content-title {
      font-size: 19px;
    }
    .intro-content-container {
      .content-item {
        width: 33.3%;
        padding-right: 10px;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .intro-content {
    padding: 0 0 0 60px;
    .intro-content-container {
      .content-item {
        width: 33.3%;
        & > div {
          width: 96%;
        }
      }
    }
  }
}
@media (min-width: 1200px) {}
</style>
