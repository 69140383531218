export const lotIntroNav = [
  {
    title: '高频彩种',
    children: [
      {
        subTitle: '黑龙江11选5',
        content: 'H1'
      },
      {
        subTitle: '重庆分分彩',
        content: 'H2'
      },
      {
        subTitle: '重庆五分彩',
        content: 'H3'
      },
      {
        subTitle: '辽宁11选5',
        content: 'H4'
      },
      {
        subTitle: '经典重庆时时彩',
        content: 'H5'
      },
      {
        subTitle: '皇家幸运飞艇',
        content: 'H6'
      },
      {
        subTitle: '湖南快乐十分',
        content: 'H7'
      },
      {
        subTitle: '湖北快三',
        content: 'H8'
      },
      {
        subTitle: '湖北11选5',
        content: 'H9'
      },
      {
        subTitle: '河南快3',
        content: 'H10'
      },
      {
        subTitle: '江西11选5',
        content: 'H11'
      },
      {
        subTitle: '江苏快三',
        content: 'H12'
      },
      {
        subTitle: '江苏11选5',
        content: 'H13'
      },
      {
        subTitle: '欢乐生肖',
        content: 'H14'
      },
      {
        subTitle: '新疆时时彩',
        content: 'H15'
      },
      {
        subTitle: '广西快乐十分',
        content: 'H16'
      },
      {
        subTitle: '广西快三',
        content: 'H17'
      },
      {
        subTitle: '广西11选5',
        content: 'H18'
      },
      {
        subTitle: '广东快乐十分',
        content: 'H19'
      },
      {
        subTitle: '广东11选5',
        content: 'H20'
      },
      {
        subTitle: '幸运飞艇',
        content: 'H21'
      },
      {
        subTitle: '幸运农场',
        content: 'H22'
      },
      {
        subTitle: '山东11选5',
        content: 'H23'
      },
      {
        subTitle: '安徽快三',
        content: 'H24'
      },
      {
        subTitle: '安徽11选5',
        content: 'H25'
      },
      {
        subTitle: '天津时时彩',
        content: 'H26'
      },
      {
        subTitle: '天津快乐十分',
        content: 'H27'
      },
      {
        subTitle: '四川快乐十二',
        content: 'H28'
      },
      {
        subTitle: '吉林快三',
        content: 'H29'
      },
      {
        subTitle: '北京PK10',
        content: 'H30'
      },
      {
        subTitle: '云南11选5',
        content: 'H31'
      },
      {
        subTitle: '上海时时乐',
        content: 'H32'
      },
      {
        subTitle: '上海11选5',
        content: 'H33'
      }
    ]
  },
  {
    title: '低频彩种',
    children: [
      {
        subTitle: '福彩3D',
        content: 'L1'
      },
      {
        subTitle: '中国体育彩票排列五',
        content: 'L2'
      },
      {
        subTitle: '中国体育彩票排列三',
        content: 'L3'
      },
      {
        subTitle: '香港六合彩',
        content: 'L4'
      }
    ]
  },
  {
    title: '极速彩种',
    children: [
      {
        subTitle: '极速赛车',
        content: 'S1'
      },
      {
        subTitle: '奇趣分分彩',
        content: 'S2'
      },
      {
        subTitle: '香港PK10',
        content: 'S3'
      },
      {
        subTitle: '多彩重庆30秒',
        content: 'S4'
      },
      {
        subTitle: '多彩腾讯30秒',
        content: 'S5'
      },
      {
        subTitle: 'QQ30秒',
        content: 'S6'
      }
    ]
  },
  {
    title: '境外彩种',
    children: [
      {
        subTitle: '河内分分彩',
        content: 'O1'
      },
      {
        subTitle: '河内五分彩',
        content: 'O2'
      }
    ]
  },
  {
    title: '计算型彩种',
    children: [
      {
        subTitle: '腾讯分分彩',
        content: 'C1'
      },
      {
        subTitle: '腾讯五分彩',
        content: 'C2'
      },
      {
        subTitle: '腾讯2分彩-双',
        content: 'C3'
      },
      {
        subTitle: '腾讯2分彩-单',
        content: 'C4'
      },
      {
        subTitle: 'QQ分分彩',
        content: 'C5'
      },
      {
        subTitle: '騰訊PK10',
        content: 'C6'
      }
    ]
  }
]
