<template>
  <div class="intro-nav pointer">
    <div
      class="intro-nav-title"
      v-if="showTitle"
      @click="handleComponentDefault">
      彩种介绍
    </div>
    <div
      class="intro-nav-list"
      v-for="(item, index) in lotIntroNav" :key="index">
        <div
          class="intro-nav-item"
          :class="openNavIndex === index ? 'in-active' : ''"
          @click="handleOpenNavIndex(index)">
            {{item.title}}
          <div class="arrow-fr">
            <img v-if="openNavIndex === index" src="@/assets/arrow-up.png">
            <img v-if="openNavIndex !== index" src="@/assets/arrow-down.png">
          </div>
        </div>
        <div :class="openNavIndex === index ? 'display' : ''" class="intro-subnav">
          <div
            v-for="(itemChild, indexChild) in item.children" :key="indexChild">
              <div
                :class="openSubnavIndex === indexChild ? 'active' : ''"
                class="intro-subnav-item" @click="handleOpenDetail(itemChild, indexChild)">
                  {{itemChild.subTitle}}
              </div>
          </div>
        </div>
        <div v-if="index !== lotIntroNav.length -1" class="nav-line"></div>
    </div>
</div>
</template>

<script>
import { lotIntroNav } from '@/constants/lotIntroNav'

export default {
  data () {
    return {
      lotIntroNav: lotIntroNav
    }
  },
  props: ['keepNow', 'openNavIndex', 'openSubnavIndex', 'isIntro', 'showTitle'],
  methods: {
    handleComponentDefault () {
      this.$emit('keepNow')
    },
    handleOpenDetail (item, index) {
      window.scrollTo({top: 0, behavior: 'smooth'});
      this.$emit('openDetail', item, index, 'LotDetail')
    },
    handleOpenNavIndex (index) {
      this.$emit('openNavIndex', null, index)
    }
  }
}
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
.arrow-fr {
  float: right;
  margin-right: 30px;
}
.display {
  display: block !important;
}
.active {
  font-weight: 800;
  background: #C8F2FF;
  color: #005FDE !important;
}
.in-active {
  color: #005FDE !important;
}
.un-active {
  color: #BDBDBD !important;
}
.nav-line {
  width: 100%;
  border: 1px solid #eee;
}
.intro-nav {
  display: inline-table;
  width: 290px;
  height: fit-content;
  background: #FFFFFF;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  .intro-nav-list {
    width: inherit;
    .intro-nav-item {
      padding: 15px 0 15px 30px;
      font-weight: 500;
      font-size: 20px;
      text-align: left;
      &:hover {color: #005FDE;}
      &:last-child {
        padding: 15px 0 30px 30px;
      }
    }
    .intro-subnav {
      display: none;
      background: #E0F7FF;
      .intro-subnav-item {
        text-align: left;
        font-weight: 400;
        font-size: 16px;
        padding: 15px 0 15px 30px;
        color: #4F4F4F;
        &:hover {
          color: #2F84ED;   
          font-weight: 500;
          background-color: #C8F2FF;
        }
      }
    }
  }
  .intro-nav-title {
    color: #fff;
    font-weight: 500;
    font-size: 21px;
    padding: 15px 30px;
    text-align: left;
    width: 290px;
    height: 60px;
    background: linear-gradient(78.86deg, #0053FF 6.92%, #579FFF 91.5%);
    border-radius: 5px 5px 0px 0px;
  }
}
@media (max-width: 575.98px) {
  .intro-nav {
    width: 100%;
    box-shadow: none;
    .intro-nav-title {
      width: 100%;
      height: fit-content;
      font-size: 21px;
      font-weight: 500;
      line-height: 30px;
      color: #FFF;
      background: #56CCF2;
      padding: 18px 0;
      border-radius: 0;
      text-align: center;
    }
    .intro-nav-list {display: none;}
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .intro-nav {
    width: 100%;
    box-shadow: none;
    .intro-nav-title {
      width: 100%;
      height: fit-content;
      font-size: 21px;
      font-weight: 500;
      line-height: 30px;
      color: #FFF;
      background: #56CCF2;
      padding: 18px 0;
      border-radius: 0;
      text-align: center;
    }
    .intro-nav-list {display: none;}
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .arrow-fr {
    margin-right: 25px;
    margin-top: 1px;
    & img {
      width: 90%;
      height: auto;
    }
  }
  .intro-nav {
    width: 220px;
    .intro-nav-item {
      padding: 15px 0 15px 25px;
      font-size: 17px;
      &:last-child {
        padding: 15px 0 25px 25px;
      }
    }
    .intro-subnav {
      .intro-subnav-item {
        padding: 15px 0 15px 25px;
      }
    }
    .intro-nav-title {
      font-size: 19px;
      padding: 15px 25px;
      width: 220px;
      height: 55px;
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .intro-nav {
    width: 250px;
    .intro-nav-title {
      width: 250px;
    }
  }
}
@media (min-width: 1200px) {}
</style>
