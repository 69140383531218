<template>
  <div class="intro-container">
    <div v-if="isPage('LotteryIntro')">
      <div class="content">
        <lot-nav
          @keepNow="handleComponentDefault"
          @openDetail="handleOpenDetail(arguments)"
          @openNavIndex="handleOpenNavIndex(arguments)"
          :showTitle="showTitle"
          :openNavIndex="openNavIndex"
          :openSubnavIndex="openSubnavIndex" />
        <keep-alive>
          <component
            @openDetail="handleOpenDetail(arguments)"
            :is="keepNow"
            :data="data" />
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import LotNav from '@/components/LotteryIntro/lotNav'
import LotList from '@/components/LotteryIntro/lotList'
import LotDetail from '@/components/LotteryIntro/lotDetail'
import { isMobile } from '@/utils/device'

export default {
  components: {
    LotNav,
    LotList,
    LotDetail
  },
  props: {
    reloadPage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      keepNow: 'LotList',
      openNavIndex: null,
      openSubnavIndex: null,
      data: null,
      showTitle: true
    }
  },
  computed: {
    isMobile () {
      return isMobile()
    }
  },
  methods: {
    handleComponentDefault () {
      this.keepNow = 'LotList'
      this.openNavIndex = null
      this.openSubnavIndex = null
      this.showTitle = true
      this.$emit('refreshView', false)
    },
    handleOpenDetail (item) {
      this.showTitle = false
      this.data = item[0]
      this.openSubnavIndex = item[1]
      this.keepNow = item[2]
      if (item[3] !== undefined) {
        this.openSubnavIndex = item[1]
        this.openNavIndex = item[3]
      }
      this.$emit('refreshView', false)
    },
    handleOpenNavIndex (item) {
      this.openSubnavIndex = item[0]
      if (this.openNavIndex === item[1]) {
        this.openNavIndex = null
      } else {
        this.openNavIndex = item[1]
      }
    },
    isPage (routeName) {
      return this.$route.name === routeName
    }
  },
  watch: {
    deep: true,
    reloadPage (cur, prev) {
      if (cur) {
        this.handleComponentDefault()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.intro-container {
  padding: 20px 0;
  .content {
    padding: 50px;
    display: flex;
    background-color: #FFFFFF;
    border-radius: 5px;
  }
}
@media (max-width: 575.98px) {
  .intro-container {
    padding: 0;
    .content {
      flex-direction: column;
      padding: 0;
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .intro-container {
    padding: 0;
    .content {
      flex-direction: column;
      padding: 0;
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .intro-container {
    width: 98%;
    .content {
      padding: 20px;
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .intro-container {
    width: 97%;
    .content {
      padding: 30px;
    }
  }
}
@media (min-width: 1200px) {}
</style>
