<template>
  <div class="intro-content">
    <div class="intro-detail-title">{{data.subTitle}}玩法规则</div>
    <keep-alive>
      <component
        class="intro-detail-content"
        :is="data.content" />
    </keep-alive>
    <!-- <div v-html="data.content" class="intro-detail-content"></div> -->
  </div>
</template>

<script>
import H1 from '../lotContent/H1'
import H2 from '../lotContent/H2'
import H3 from '../lotContent/H3'
import H4 from '../lotContent/H4'
import H5 from '../lotContent/H5'
import H6 from '../lotContent/H6'
import H7 from '../lotContent/H7'
import H8 from '../lotContent/H8'
import H9 from '../lotContent/H9'
import H10 from '../lotContent/H10'
import H11 from '../lotContent/H11'
import H12 from '../lotContent/H12'
import H13 from '../lotContent/H13'
import H14 from '../lotContent/H14'
import H15 from '../lotContent/H15'
import H16 from '../lotContent/H16'
import H17 from '../lotContent/H17'
import H18 from '../lotContent/H18'
import H19 from '../lotContent/H19'
import H20 from '../lotContent/H20'
import H21 from '../lotContent/H21'
import H22 from '../lotContent/H22'
import H23 from '../lotContent/H23'
import H24 from '../lotContent/H24'
import H25 from '../lotContent/H25'
import H26 from '../lotContent/H26'
import H27 from '../lotContent/H27'
import H28 from '../lotContent/H28'
import H29 from '../lotContent/H29'
import H30 from '../lotContent/H30'
import H31 from '../lotContent/H31'
import H32 from '../lotContent/H32'
import H33 from '../lotContent/H33'
import L1 from '../lotContent/L1'
import L2 from '../lotContent/L2'
import L3 from '../lotContent/L3'
import L4 from '../lotContent/L4'
import O1 from '../lotContent/O1'
import O2 from '../lotContent/O2'
import S1 from '../lotContent/S1'
import S2 from '../lotContent/S2'
import S3 from '../lotContent/S3'
import S4 from '../lotContent/S4'
import S5 from '../lotContent/S5'
import S6 from '../lotContent/S6'
import C1 from '../lotContent/C1'
import C2 from '../lotContent/C2'
import C3 from '../lotContent/C3'
import C4 from '../lotContent/C4'
import C5 from '../lotContent/C5'
import C6 from '../lotContent/C6'

export default {
  components: {
    H1, H2, H3, H4, H5, H6, H7, H8, H9, H10, H11, H12, H13, H14, H15, H16, H17, H18, H19, H20, H21, H22, H23, H24, H25, H26, H27, H28, H29, H30, H31, H32, H33, L1, L2, L3, L4, O1, O2, S1, S2, S3, S4, S5, S6, C1, C2, C3, C4, C5, C6
  },
  props: ['data']
}
</script>

<style lang="scss" scoped>
.active {
  font-weight: 800;
  color: #005FDE !important;
}
.intro-content {
  text-align: left;
  padding: 0 0 0 70px;
  .intro-detail-title {
    font-weight: 500;
    font-size: 28px;
    margin-bottom: 30px;
  }
  .intro-detail-content {
    font-size: 15px;
    line-height: 25px;
  }
}
@media (max-width: 575.98px) {
  .intro-content {
    padding: 0;
    color: #4F4F4F;
    .intro-detail-title {
      font-weight: 500;
      font-size: 21px;
      text-align: center;
      margin: 15px 0;
    }
    .intro-detail-content {
      font-size: 15px;
      line-height: 25px;
      padding: 0 15px 50px;
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .intro-content {
    padding: 0;
    color: #4F4F4F;
    .intro-detail-title {
      font-weight: 500;
      font-size: 21px;
      text-align: center;
      margin: 15px 0;
    }
    .intro-detail-content {
      font-size: 15px;
      line-height: 25px;
      padding: 0 15px 50px;
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {}
@media (min-width: 992px) and (max-width: 1199.98px) {}
@media (min-width: 1200px) {}
</style>
